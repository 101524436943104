import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {Payload} from "@cat2/legacy-meta-cat/lib/shared/metadata/payload";
import {catchError, map} from "rxjs/operators";
import {
  CreateSpanGQL,
  CreateSpanInput,
  DeleteSpanGQL,
  Department,
  GetSpansOfDepartmentGQL,
  UpdateSpanGQL,
  UpdateSpanInput
} from "../../graphql/graphql";
import {jsDateToUtcOffsetSeconds, utcOffsetSecondsToJsDate} from "@core/helpers/parse-offset";
import {DateTime} from "luxon";
import {Cat2NotificationService} from "@cat2/legacy-meta-cat";

@Injectable({
  providedIn: 'root'
})
export class SpanClientService {
  departmentName = new BehaviorSubject<Department | undefined>(undefined);

  constructor(
    private getSpanGQL: GetSpansOfDepartmentGQL,
    private deleteSpanGQL: DeleteSpanGQL,
    private createSpanGQL: CreateSpanGQL,
    private updateSpanGQL: UpdateSpanGQL,
    private _notification: Cat2NotificationService
  ) {
  }

  readAllRecords(): Observable<Payload<any[]>> {
    const executionStart = new Date();
    return this.getSpanGQL.fetch({guid: {eq: this.departmentName.value?.guid}}, {fetchPolicy: "no-cache"})
      .pipe(
        map(res => this.toPayload(res.data.span?.items ?? [], executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
  }

  readRecord(id: string): Observable<Payload<any>> {
    console.log('readRecord')
    return of({} as Payload<any>);
  }

  updateRecord(id: string, userInput: any): Observable<Payload<any>> {
    const executionStart = new Date();
    if(userInput.dayStartTime>userInput.dayEndTime)
    {
      userInput.dayEndTime.setDate(userInput.dayEndTime.getDate()+1);
    }
    let spanDayStartOffset = (jsDateToUtcOffsetSeconds(userInput.dayStartTime)-userInput.departmentStartOffset);
    let spanDuration = (userInput.dayEndTime.getTime()-userInput.dayStartTime.getTime())/1000;
    if ( spanDayStartOffset<0)
    {
      this._notification.error("Span Start time cannot be earlier than Department Start time.");
      return of({} as Payload<any>);
    }
    if ( spanDuration>86399)
    {
      this._notification.error("Span Duration cannot be greater than 24 hours.");
      return of({} as Payload<any>);
    }
    const parsedInput: UpdateSpanInput = {
      guid: userInput.guid,
      setClassification: {value: userInput.classification},
      setDayStartOffset: {value: spanDayStartOffset},
      setDepartmentGuid: {value: this.departmentName.value?.guid ?? ''},
      setDescription: {value: userInput.description},
      setDuration: {value: spanDuration},
      setId: {value: userInput.id},
    };
    return this.updateSpanGQL.mutate({input: parsedInput})
      .pipe(
        map((res: any) => this.toPayload(res.data.updateDepartment, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString()))
        ));
  }

  createClass(item: any): Observable<Payload<any>> {
    const executionStart = new Date();
    if(item.dayStartTime>item.dayEndTime)
    {
      item.dayEndTime.setDate(item.dayEndTime.getDate()+1);
    }
    let spanDayStartOffset = (jsDateToUtcOffsetSeconds(item.dayStartTime)-this.departmentName.value?.dayStartOffSet!);
    let spanDuration = (item.dayEndTime.getTime()-item.dayStartTime.getTime())/1000;
    if ( spanDayStartOffset<0)
    {
      this._notification.error("Span Start time cannot be earlier than Department Start time.");
      return of({} as Payload<any>);
    }
    if ( spanDuration>86399)
    {
      this._notification.error("Span Duration cannot be greater than 24 hours.");
      return of({} as Payload<any>);
    }
    const parsedInput: CreateSpanInput = {
      classification: item.classification,
      dayStartOffset: spanDayStartOffset,
      departmentGuid: this.departmentName.value?.guid ?? '',
      description: item.description,
      duration: (item.dayEndTime.getTime()-item.dayStartTime.getTime())/1000,
      id: item.id,
    }
    return this.createSpanGQL.mutate({input: parsedInput})
      .pipe(
        map((res: any) => this.toPayload(res.data.createSpan, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
  }

  deleteRecord(id: string): Observable<Payload<boolean>> {
    const executionStart = new Date();
    return this.deleteSpanGQL.mutate({input: {guid: id}}).pipe(
      map((res: any) => this.toPayload(undefined, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  private toPayload(items: any, executionStart: Date, errorMsg?: string): Payload<any> {
    return {
      data: Array.isArray(items) ? items.map(i => this.parseItems(i)) : this.parseItems(items),
      txGuid: '',
      executionStart: executionStart,
      executionEnd: new Date(),
      statusMessage: errorMsg ? errorMsg : 'ok',
      status: errorMsg ? 2 : 0,
    }
  }
  private parseItems(item: any) {
    if (item) {
      const startTime = utcOffsetSecondsToJsDate(item.dayStartOffset+this.departmentName.value?.dayStartOffSet!)
      return {
        ...item,
        __id: item.guid,
        dayStartTime: startTime,
        dayEndTime: DateTime.fromJSDate(startTime).plus({second:item.duration}).toJSDate(),
        departmentStartOffset: this.departmentName.value?.dayStartOffSet!
      }
    }
  }
}
