<cat2-app-shell
  [disableApps]="false"
  [disableNotifications]="true"
  [disableSearch]="true"
  [desktopNavVisible]="true"
  [mobileNavVisible]="false"
>
  <router-outlet>
  </router-outlet>
</cat2-app-shell>
