import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: Date; output: Date; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: number; output: number; }
  UUID: { input: string; output: string; }
  /** The UnsignedLong scalar type represents a unsigned 64-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedLong: { input: number; output: number; }
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type CreateDepartmentInput = {
  dayStartOffSet: Scalars['Long']['input'];
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSettingInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSpanInput = {
  classification: SpanClassifications;
  dayStartOffset: Scalars['UnsignedLong']['input'];
  departmentGuid: Scalars['UUID']['input'];
  description: Scalars['String']['input'];
  duration: Scalars['UnsignedLong']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteDepartmentInput = {
  guid: Scalars['UUID']['input'];
};

export type DeleteSettingInput = {
  key: Scalars['String']['input'];
};

export type DeleteSpanInput = {
  guid: Scalars['UUID']['input'];
};

export type Department = {
  __typename?: 'Department';
  dayStartOffSet: Scalars['Long']['output'];
  description: Scalars['String']['output'];
  guid: Scalars['UUID']['output'];
  id: Scalars['String']['output'];
  spans: Array<Span>;
};


export type DepartmentSpansArgs = {
  order?: InputMaybe<Array<SpanSortInput>>;
  where?: InputMaybe<SpanFilterInput>;
};

/** A segment of a collection. */
export type DepartmentCollectionSegment = {
  __typename?: 'DepartmentCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Department>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type DepartmentFilterInput = {
  and?: InputMaybe<Array<DepartmentFilterInput>>;
  dayStartOffSet?: InputMaybe<LongOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DepartmentFilterInput>>;
  spans?: InputMaybe<ListFilterInputTypeOfSpanFilterInput>;
};

export type DepartmentSortInput = {
  dayStartOffSet?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type ListFilterInputTypeOfSpanFilterInput = {
  all?: InputMaybe<SpanFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SpanFilterInput>;
  some?: InputMaybe<SpanFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createDepartment?: Maybe<Department>;
  createSetting?: Maybe<Setting>;
  createSpan?: Maybe<Span>;
  deleteDepartment?: Maybe<Department>;
  deleteSetting?: Maybe<Setting>;
  deleteSpan?: Maybe<Span>;
  updateDepartment?: Maybe<Department>;
  updateSetting?: Maybe<Setting>;
  updateSpan?: Maybe<Span>;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateSettingArgs = {
  input: CreateSettingInput;
};


export type MutationCreateSpanArgs = {
  input: CreateSpanInput;
};


export type MutationDeleteDepartmentArgs = {
  input: DeleteDepartmentInput;
};


export type MutationDeleteSettingArgs = {
  input: DeleteSettingInput;
};


export type MutationDeleteSpanArgs = {
  input: DeleteSpanInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateSettingArgs = {
  input: UpdateSettingInput;
};


export type MutationUpdateSpanArgs = {
  input: UpdateSpanInput;
};

export type Query = {
  __typename?: 'Query';
  department?: Maybe<DepartmentCollectionSegment>;
  departmentById?: Maybe<Department>;
  span?: Maybe<SpanCollectionSegment>;
  spanById?: Maybe<Span>;
};


export type QueryDepartmentArgs = {
  date: Scalars['Date']['input'];
  order?: InputMaybe<Array<DepartmentSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DepartmentFilterInput>;
};


export type QueryDepartmentByIdArgs = {
  date: Scalars['Date']['input'];
  guid: Scalars['UUID']['input'];
};


export type QuerySpanArgs = {
  order?: InputMaybe<Array<SpanSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpanFilterInput>;
};


export type QuerySpanByIdArgs = {
  guid: Scalars['UUID']['input'];
};

export type SetNullableValueInputOfStringInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SetValueInputOfGuidInput = {
  value: Scalars['UUID']['input'];
};

export type SetValueInputOfInt64Input = {
  value: Scalars['Long']['input'];
};

export type SetValueInputOfSpanClassificationsInput = {
  value: SpanClassifications;
};

export type SetValueInputOfStringInput = {
  value: Scalars['String']['input'];
};

export type SetValueInputOfUInt64Input = {
  value: Scalars['UnsignedLong']['input'];
};

export type Setting = {
  __typename?: 'Setting';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Span = {
  __typename?: 'Span';
  classification: SpanClassifications;
  dayStartOffset?: Maybe<Scalars['UnsignedLong']['output']>;
  department?: Maybe<Department>;
  departmentGuid: Scalars['UUID']['output'];
  description: Scalars['String']['output'];
  duration?: Maybe<Scalars['UnsignedLong']['output']>;
  guid: Scalars['UUID']['output'];
  id: Scalars['String']['output'];
};


export type SpanDepartmentArgs = {
  date: Scalars['Date']['input'];
};

export enum SpanClassifications {
  Exclusion = 'EXCLUSION',
  Shift = 'SHIFT'
}

export type SpanClassificationsOperationFilterInput = {
  eq?: InputMaybe<SpanClassifications>;
  in?: InputMaybe<Array<SpanClassifications>>;
  neq?: InputMaybe<SpanClassifications>;
  nin?: InputMaybe<Array<SpanClassifications>>;
};

/** A segment of a collection. */
export type SpanCollectionSegment = {
  __typename?: 'SpanCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Span>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SpanFilterInput = {
  and?: InputMaybe<Array<SpanFilterInput>>;
  classification?: InputMaybe<SpanClassificationsOperationFilterInput>;
  dayStartOffset?: InputMaybe<UnsignedLongOperationFilterInputType>;
  department?: InputMaybe<DepartmentFilterInput>;
  departmentGuid?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  duration?: InputMaybe<UnsignedLongOperationFilterInputType>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SpanFilterInput>>;
};

export type SpanSortInput = {
  classification?: InputMaybe<SortEnumType>;
  dayStartOffset?: InputMaybe<SortEnumType>;
  department?: InputMaybe<DepartmentSortInput>;
  departmentGuid?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  duration?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type UnsignedLongOperationFilterInputType = {
  eq?: InputMaybe<Scalars['UnsignedLong']['input']>;
  gt?: InputMaybe<Scalars['UnsignedLong']['input']>;
  gte?: InputMaybe<Scalars['UnsignedLong']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UnsignedLong']['input']>>>;
  lt?: InputMaybe<Scalars['UnsignedLong']['input']>;
  lte?: InputMaybe<Scalars['UnsignedLong']['input']>;
  neq?: InputMaybe<Scalars['UnsignedLong']['input']>;
  ngt?: InputMaybe<Scalars['UnsignedLong']['input']>;
  ngte?: InputMaybe<Scalars['UnsignedLong']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UnsignedLong']['input']>>>;
  nlt?: InputMaybe<Scalars['UnsignedLong']['input']>;
  nlte?: InputMaybe<Scalars['UnsignedLong']['input']>;
};

export type UpdateDepartmentInput = {
  guid: Scalars['UUID']['input'];
  setDayStartOffSet?: InputMaybe<SetValueInputOfInt64Input>;
  setDescription?: InputMaybe<SetValueInputOfStringInput>;
  setId?: InputMaybe<SetNullableValueInputOfStringInput>;
};

export type UpdateSettingInput = {
  key: Scalars['String']['input'];
  setValue?: InputMaybe<SetNullableValueInputOfStringInput>;
};

export type UpdateSpanInput = {
  guid: Scalars['UUID']['input'];
  setClassification?: InputMaybe<SetValueInputOfSpanClassificationsInput>;
  setDayStartOffset?: InputMaybe<SetValueInputOfUInt64Input>;
  setDepartmentGuid?: InputMaybe<SetValueInputOfGuidInput>;
  setDescription?: InputMaybe<SetValueInputOfStringInput>;
  setDuration?: InputMaybe<SetValueInputOfUInt64Input>;
  setId?: InputMaybe<SetNullableValueInputOfStringInput>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateDepartmentMutationVariables = Exact<{
  input: CreateDepartmentInput;
}>;


export type CreateDepartmentMutation = { __typename?: 'Mutation', createDepartment?: { __typename?: 'Department', dayStartOffSet: number, guid: string, description: string, id: string } | null };

export type CreateSpanMutationVariables = Exact<{
  input: CreateSpanInput;
}>;


export type CreateSpanMutation = { __typename?: 'Mutation', createSpan?: { __typename?: 'Span', guid: string, departmentGuid: string, id: string, classification: SpanClassifications, description: string, dayStartOffset?: number | null, duration?: number | null } | null };

export type DeleteDepartmentMutationVariables = Exact<{
  input: DeleteDepartmentInput;
}>;


export type DeleteDepartmentMutation = { __typename?: 'Mutation', deleteDepartment?: { __typename?: 'Department', guid: string, id: string, description: string } | null };

export type DeleteSpanMutationVariables = Exact<{
  input: DeleteSpanInput;
}>;


export type DeleteSpanMutation = { __typename?: 'Mutation', deleteSpan?: { __typename?: 'Span', guid: string, id: string, description: string, duration?: number | null } | null };

export type DepartmentQueryVariables = Exact<{
  date: Scalars['Date']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<DepartmentSortInput> | DepartmentSortInput>;
}>;


export type DepartmentQuery = { __typename?: 'Query', department?: { __typename?: 'DepartmentCollectionSegment', totalCount: number, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean }, items?: Array<{ __typename?: 'Department', id: string, description: string, dayStartOffSet: number, guid: string }> | null } | null };

export type GetSpansOfDepartmentQueryVariables = Exact<{
  guid: UuidOperationFilterInput;
}>;


export type GetSpansOfDepartmentQuery = { __typename?: 'Query', span?: { __typename?: 'SpanCollectionSegment', totalCount: number, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean }, items?: Array<{ __typename?: 'Span', guid: string, id: string, description: string, dayStartOffset?: number | null, duration?: number | null, classification: SpanClassifications }> | null } | null };

export type UpdateDepartmentMutationVariables = Exact<{
  input: UpdateDepartmentInput;
}>;


export type UpdateDepartmentMutation = { __typename?: 'Mutation', updateDepartment?: { __typename?: 'Department', dayStartOffSet: number, guid: string, description: string, id: string } | null };

export type UpdateSpanMutationVariables = Exact<{
  input: UpdateSpanInput;
}>;


export type UpdateSpanMutation = { __typename?: 'Mutation', updateSpan?: { __typename?: 'Span', dayStartOffset?: number | null, guid: string, description: string, id: string, duration?: number | null } | null };

export const CreateDepartmentDocument = gql`
    mutation CreateDepartment($input: CreateDepartmentInput!) {
  createDepartment(input: $input) {
    dayStartOffSet
    guid
    description
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDepartmentGQL extends Apollo.Mutation<CreateDepartmentMutation, CreateDepartmentMutationVariables> {
    override document = CreateDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSpanDocument = gql`
    mutation CreateSpan($input: CreateSpanInput!) {
  createSpan(input: $input) {
    guid
    departmentGuid
    id
    classification
    description
    dayStartOffset
    duration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSpanGQL extends Apollo.Mutation<CreateSpanMutation, CreateSpanMutationVariables> {
    override document = CreateSpanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDepartmentDocument = gql`
    mutation DeleteDepartment($input: DeleteDepartmentInput!) {
  deleteDepartment(input: $input) {
    guid
    id
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDepartmentGQL extends Apollo.Mutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables> {
    override document = DeleteDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSpanDocument = gql`
    mutation DeleteSpan($input: DeleteSpanInput!) {
  deleteSpan(input: $input) {
    guid
    id
    description
    duration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSpanGQL extends Apollo.Mutation<DeleteSpanMutation, DeleteSpanMutationVariables> {
    override document = DeleteSpanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DepartmentDocument = gql`
    query Department($date: Date!, $skip: Int, $take: Int, $order: [DepartmentSortInput!]) {
  department(date: $date, skip: $skip, take: $take, order: $order) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    items {
      id
      description
      dayStartOffSet
      guid
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepartmentGQL extends Apollo.Query<DepartmentQuery, DepartmentQueryVariables> {
    override document = DepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSpansOfDepartmentDocument = gql`
    query GetSpansOfDepartment($guid: UuidOperationFilterInput!) {
  span(where: {departmentGuid: $guid}) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    items {
      guid
      id
      description
      dayStartOffset
      duration
      classification
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSpansOfDepartmentGQL extends Apollo.Query<GetSpansOfDepartmentQuery, GetSpansOfDepartmentQueryVariables> {
    override document = GetSpansOfDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDepartmentDocument = gql`
    mutation UpdateDepartment($input: UpdateDepartmentInput!) {
  updateDepartment(input: $input) {
    dayStartOffSet
    guid
    description
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDepartmentGQL extends Apollo.Mutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables> {
    override document = UpdateDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSpanDocument = gql`
    mutation UpdateSpan($input: UpdateSpanInput!) {
  updateSpan(input: $input) {
    dayStartOffset
    guid
    description
    id
    duration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSpanGQL extends Apollo.Mutation<UpdateSpanMutation, UpdateSpanMutationVariables> {
    override document = UpdateSpanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }